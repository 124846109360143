@import "./variables";

.hero-heading-c1,
.hero-heading-c2 {
    @include font-smoothing;
    font-family: $latin-heading-font;
    font-weight: $latin-heading-font-weight;
}

.hero-heading-c1 {
    font-size: rem(36);
    line-height: rem(48);
}

.hero-heading-c2 {
    font-size: rem(30);
    line-height: rem(42);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-smoothing;
    font-family: $latin-heading-font;
    font-weight: $latin-heading-font-weight;
    margin: 0;
    padding: rem(30) 0 rem(12) 0;
}

.h1,
h1 {
    font-size: rem(26);
    line-height: rem(38);
}

.h2,
h2 {
    font-size: rem(22);
    line-height: rem(34);
}

.h3,
h3 {
    font-size: rem(18);
    line-height: rem(28);
}

.h4,
h4 {
    font-size: rem(16);
    line-height: rem(26);
}

.h5,
h5 {
    font-size: rem(16);
    line-height: rem(26);
}

.h6,
h6 {
    font-size: rem(14);
    line-height: rem(24);
}

p {
    margin: 0;
    padding: rem(11) 0;
    font-size: rem(14);
    line-height: rem(24);
}

ul,
ol {
    padding: rem(11) rem(40);
    font-size: rem(14);
    line-height: rem(24);

    li:not([class]) {
        padding: rem(11) 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

ul {
    list-style: none;

    li:not([class]) {
        position: relative;

        &:first-child {
            &:before {
                top: rem(11);
            }
        }

        &:before {
            content: "";
            width: rem(5);
            height: rem(5);
            background-color: $charcoal;
            position: absolute;
            left: rem(-15);
            top: rem(21);
            display: block;
            border-radius: rem(999);
        }
    }
}

ol {
    list-style: none;
    counter-reset: custom-counter;

    li {
        position: relative;
        counter-increment: custom-counter;

        &:first-child {
            &:before {
                top: 0;
            }
        }

        &:before {
            content: counter(custom-counter) ".";
            color: $charcoal;
            font-size: rem(12);
            line-height: rem(22);
            font-family: $latin-body-font;
            font-weight: 600;
            position: absolute;
            left: rem(-18);
            top: rem(12);
        }
    }
}

blockquote {
    font-size: rem(18);
    line-height: rem(28);
    font-family: $latin-heading-font;
    font-weight: $latin-heading-font-weight;
    margin: 0;
    padding: rem(30) rem(40);

    p,
    * {
        font-size: rem(18);
        line-height: rem(28);
        font-family: $latin-heading-font;
        font-weight: $latin-heading-font-weight;
    }
}

a {
    color: $bluish;
    text-decoration: underline;

    &:focus {
        outline: 0;
    }

    &[target="_blank"] {
        &:after {
            content: url("../assets/img/icon-new-tab.svg");
            margin: 0 0 0 rem(6);
        }
    }
}

@media screen and (min-width: map-get($grid-breakpoints, "md")) {
    .hero-heading-c1 {
        font-size: rem(68);
        line-height: rem(78);
    }

    .hero-heading-c2 {
        font-size: rem(42);
        line-height: rem(52);
    }

    .h2,
    h2 {
        font-size: rem(27);
        line-height: rem(37);
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }
}

@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    .hero-heading-c1 {
        font-size: rem(94);
        line-height: rem(110);
    }

    .hero-heading-c2 {
        font-size: rem(62);
        line-height: rem(72);
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: rem(30) 0 rem(20) 0;
    }

    .h1,
    h1 {
        font-size: rem(30);
        line-height: rem(40);
    }

    .h2,
    h2 {
        font-size: rem(36);
        line-height: rem(47);
    }

    .h3,
    h3 {
        font-size: rem(26);
        line-height: rem(36);
    }

    ul,
    ol {
        padding: rem(11) rem(50);
    }

    ol li {
        &:before {
            font-size: rem(14);
            line-height: rem(24);
        }
    }

    p {
        font-size: rem(16);
        line-height: rem(26);
    }

    ul:not([class]),
    ol {
        font-size: rem(16);
        line-height: rem(26);
    }

    blockquote {
        font-size: rem(26);
        line-height: rem(36);
        padding: rem(30) rem(50);

        p,
        * {
            font-size: rem(26);
            line-height: rem(36);
        }
    }
}

@media screen and (min-width: map-get($grid-breakpoints, "xl")) {
    .hero-heading-c1 {
        font-size: rem(114);
        line-height: rem(130);
    }

    .hero-heading-c2 {
        font-size: rem(92);
        line-height: rem(102);
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: rem(70) 0 rem(20) 0;
    }

    .h1,
    h1 {
        font-size: rem(36);
        line-height: rem(46);
    }

    .h2,
    h2 {
        font-size: rem(46);
        line-height: rem(56);
    }

    .h3,
    h3 {
        font-size: rem(36);
        line-height: rem(46);
    }

    .h4,
    h4 {
        font-size: rem(21);
        line-height: rem(32);
    }

    .h5,
    h5 {
        font-size: rem(19);
        line-height: rem(28);
    }

    .h6,
    h6 {
        font-size: rem(16);
        line-height: rem(24);
    }
}

@each $theme, $item in $template-themes {
    $themeColour: $item;

    body.#{$theme} {
        ul {
            li:not([class]) {
                &:before {
                    background-color: $themeColour;
                }
            }
        }
    }
}

@each $bg, $item in $component-bg-styles-dark {
    .component-background.#{$bg} {
        ul {
            li:not([class]) {
                &:before {
                    background-color: $white;
                }
            }
        }
    }
}
