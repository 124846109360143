html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

@import "../../node_modules/normalize-scss/sass/normalize/import-now";
@import "./imports/_variables";

@import "./imports/_utils";
@import "./imports/_typography";

// screen readers only classes
// ======================================================

.sr-only {
    @include sr-only;
}

.no-padd {
    padding: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// ===========================================================================
.sr-only-focusable:focus,
.sr-only-focusable:active {
    @include sr-only-focusable;
}

::-moz-selection {
    background: $bluish;
    color: $white;
}
::selection {
    background: $bluish;
    color: $white;
}

// theme imports and overriding styles
// ======================================================
body {
    @include font-smoothing;
    font-family: $latin-body-font;
    font-weight: $latin-body-font-weight;
    font-size: $body-size;
    line-height: $body-line;
    font-variant-numeric: lining-nums;
    color: $charcoal;
    font-display: swap;
}

form {
    width: 100%;
    margin: 0;
    padding: 0;

    input[type="text"],
    input[type="email"],
    input[type="phone"],
    textarea {
        -webkit-appearance: none;
        width: 100%;
        border: rem(2) solid $very-light-pink;
        padding: rem(22) rem(24);
        margin: 0;
        background-color: $white;
        font-size: rem(16);
        line-height: rem(26);
        font-family: $latin-heading-font;
        font-weight: $latin-heading-font-weight;
        transition: border-color 0.4s ease;

        &::-ms-clear {
            display: none;
        }

        &::-webkit-input-placeholder {
            color: rgba($charcoal, 0.3);
        }

        &:-ms-input-placeholder {
            color: rgba($charcoal, 0.3);
        }

        &::placeholder {
            color: rgba($charcoal, 0.3);
        }
    }

    label {
        @include sr-only;
    }
}

body.arabic-text {
    font-family: $arabic-body-font;
    font-weight: $arabic-body-font-weight;
    direction: rtl;

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    blockquote,
    blockquote *,
    blockquote p,
    ol li:before,
    .hero-heading-c1,
    .hero-heading-c2,
    .btn {
        font-family: $arabic-heading-font;
        font-weight: $arabic-heading-font-weight;
    }

    ol {
        list-style: arabic-indic;

        li {
            list-style: arabic-indic;

            &:before {
                display: none;
            }
        }
    }

    ul {
        li:before {
            left: inherit;
            right: rem(-15);
        }
    }

    form {
        input[type="text"],
        input[type="email"],
        input[type="phone"],
        textarea {
            font-family: $arabic-heading-font;
            font-weight: $arabic-heading-font-weight;
        }
    }
}

body.russian-text {
    font-family: $russian-body-font;
    font-weight: $russian-body-font-weight;

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .hero-heading-c1,
    .hero-heading-c2,
    blockquote,
    blockquote *,
    blockquote p,
    .btn {
        font-family: $russian-heading-font;
        font-weight: $russian-heading-font-weight;
    }

    ol {
        li {
            &:before {
                font-family: $russian-body-font;
                font-weight: $russian-body-font-weight;
            }
        }
    }

    form {
        input[type="text"],
        input[type="email"],
        input[type="phone"],
        textarea {
            font-family: $russian-heading-font;
            font-weight: $russian-heading-font-weight;
        }
    }
}

body.chinese-text {
    font-family: $chinese-body-font;
    font-weight: $chinese-body-font-weight;

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .hero-heading-c1,
    .hero-heading-c2,
    blockquote,
    blockquote *,
    blockquote p,
    .btn {
        font-family: $chinese-heading-font;
        font-weight: $chinese-heading-font-weight;
        font-style: normal;
    }

    ol {
        li {
            &:before {
                font-family: $chinese-body-font;
                font-weight: $chinese-body-font-weight;
            }
        }
    }

    form {
        input[type="text"],
        input[type="email"],
        input[type="phone"],
        textarea {
            font-family: $chinese-heading-font;
            font-weight: $chinese-heading-font-weight;
            font-style: normal;
        }
    }
}

@each $theme, $item in $template-themes {
    body.#{$theme} {
        ::-moz-selection {
            background: $item;
            color: $white;
        }
        ::selection {
            background: $item;
            color: $white;
        }
    }
}

img {
    border-radius: rem(2);
    max-width: 100%;
    height: auto;
}

#main-content {
    position: relative;
    z-index: 1;
}

.btn {
    @include font-smoothing;
    font-family: $latin-heading-font;
    font-weight: $latin-heading-font-weight;
    font-size: rem(14);
    line-height: rem(14);
    border-radius: rem(2);
    text-align: center;
    padding: rem(11) rem(36);
    text-decoration: none;
    display: inline-block;
    width: auto;
    transition: background-color 0.5s ease, box-shadow 0.4s ease;
    background-color: $charcoal;
    color: $white;
    border: rem(3) solid $charcoal;
    cursor: pointer;

    &:focus {
        outline: 0;
    }
}

.page-wrapper {
    transition: opacity 0.4s ease, visibility 0.6s ease;
    opacity: 0;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.component-wrapper {
    @include component-spacing;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;

    &__inner {
        padding-left: rem($padding);
        padding-right: rem($padding);
    }

    @include centre-align-container;
}

@each $bg, $item in $bg-styles {
    body.#{$bg} {
        $itemColour: $item;

        .btn {
            border-color: $white;
            background-color: $white;
            color: $itemColour;
        }

        /*
            Medium Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .btn {
                &:focus,
                &:hover {
                    background-color: $itemColour;
                    color: $white;
                }
            }
        }
    }
}

@each $bg, $item in $component-bg-styles-dark {
    .component-background.#{$bg} {
        $itemColour: $item;

        .btn {
            border-color: $white;
            background-color: $white;
            color: $itemColour;
        }

        /*
            Medium Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            .btn {
                &:focus,
                &:hover {
                    background-color: $itemColour;
                    color: $white;
                }
            }
        }
    }
}

@each $theme, $item in $template-themes {
    body.#{$theme} {
        $itemColour: $item;

        .btn {
            background-color: $itemColour;
            border-color: $itemColour;
            color: $white;
        }

        /*
            Medium Screens
        */
        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            form {
                input[type="text"],
                input[type="email"],
                input[type="phone"],
                textarea {
                    &:focus {
                        outline: 0;
                        border-color: $itemColour;
                    }
                }
            }

            .btn {
                &:focus,
                &:hover {
                    background-color: $white;
                    color: $itemColour;
                }
            }
        }
    }
}
